var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: _vm.componentId },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": [_vm.componentId],
            scrollable: "",
          },
          on: { hidden: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _vm.exists
                    ? [
                        _c(
                          "div",
                          { staticClass: "history-button lock-container" },
                          [
                            _vm.isLockVisible
                              ? [
                                  _c("div", { staticClass: "ml-1 mr-1" }, [
                                    _vm._v(_vm._s(_vm.$t("lock"))),
                                  ]),
                                  _c("b-form-checkbox", {
                                    attrs: {
                                      disabled: _vm.isLockReadOnly,
                                      switch: "",
                                    },
                                    model: {
                                      value: _vm.project.readOnly,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.project, "readOnly", $$v)
                                      },
                                      expression: "project.readOnly",
                                    },
                                  }),
                                ]
                              : _vm._e(),
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "secondary", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    _vm.state.historyShow = true
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "clock-rotate-left"] },
                                }),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("button.history")) + " "
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  !_vm.isAccessDenied && _vm.exists && _vm.originProject != null
                    ? _c(
                        "b-button",
                        {
                          staticStyle: { "margin-right": "auto" },
                          attrs: { size: "sm", variant: "secondary" },
                          on: { click: _vm.preScheduleRedirect },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("task.button.edit_schedule")) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  !_vm.isAccessDenied && (_vm.canEdit() || !_vm.exists)
                    ? [
                        _vm.state.isSubmitting
                          ? _c(
                              "b-button",
                              {
                                attrs: {
                                  size: "sm",
                                  variant: "success",
                                  disabled: "",
                                },
                              },
                              [
                                _c("b-spinner", {
                                  attrs: { small: "", type: "grow" },
                                }),
                                _vm._v(_vm._s(_vm.$t("button.saving")) + " "),
                              ],
                              1
                            )
                          : _c(
                              "b-button",
                              {
                                attrs: {
                                  size: "sm",
                                  variant: "success",
                                  disabled: _vm.disableOk,
                                },
                                on: { click: _vm.ok },
                              },
                              [_vm._v(_vm._s(_vm.$t("button.ok")))]
                            ),
                      ]
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _vm.isAccessDenied
            ? [
                _c("div", { staticClass: "modal-message-overlay" }, [
                  _c("span", { staticClass: "grid-overlay" }, [
                    _vm._v(
                      _vm._s(
                        _vm.restrictedRequiredField != null
                          ? _vm.$t(
                              "entity_selector.error.insufficient_permission_to_add_entity_with_reason",
                              [
                                _vm.$t("project.title").toLowerCase(),
                                _vm.restrictedRequiredField,
                              ]
                            )
                          : _vm.$t(
                              "entity_selector.error.insufficient_permission_to_add_entity",
                              [_vm.$t("project.title").toLowerCase()]
                            )
                      )
                    ),
                  ]),
                ]),
              ]
            : [
                _vm.isAvatarBannerVisible
                  ? _c("AvatarBanner", {
                      attrs: {
                        readOnly: _vm.isAvatarBannerReadOnly,
                        baseAvatarIcon: ["fad", "chart-network"],
                        transforms: "shrink-6 left-2",
                      },
                      on: { status: _vm.avatarBannerStatus },
                      model: {
                        value: _vm.avatarBanner,
                        callback: function ($$v) {
                          _vm.avatarBanner = $$v
                        },
                        expression: "avatarBanner",
                      },
                    })
                  : _vm._e(),
                _c(
                  "b-alert",
                  {
                    attrs: {
                      variant: "danger",
                      dismissible: "",
                      show: _vm.showError,
                    },
                    on: { dismissed: _vm.dismissAlert },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "triangle-exclamation"] },
                    }),
                    _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
                    _c(
                      "ul",
                      {
                        staticClass: "mb-0",
                        attrs: { show: _vm.showErrorDetail },
                      },
                      [
                        _vm._l(_vm.alertMsgDetails, function (item, index) {
                          return [
                            _c("li", { key: index }, [_vm._v(_vm._s(item))]),
                          ]
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "container pl-0" },
                  [
                    _c(
                      "b-row",
                      [
                        _vm.isNameVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("project.field.name"),
                                      "label-for": "name",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: { required: true },
                                              expression: "{ required: true }",
                                            },
                                          ],
                                          attrs: {
                                            id: "name",
                                            type: "text",
                                            "data-vv-as":
                                              _vm.$t("project.field.name"),
                                            "data-vv-name": "project.name",
                                            "data-vv-delay": "500",
                                            readonly: _vm.isNameReadOnly,
                                            trim: "",
                                            state:
                                              _vm.fieldValidateUtil.stateValidate(
                                                _vm.isReadOnly,
                                                _vm.veeFields,
                                                _vm.errors,
                                                "project.name"
                                              ),
                                          },
                                          model: {
                                            value: _vm.project.name,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.project, "name", $$v)
                                            },
                                            expression: "project.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: { "d-block": _vm.showNameError },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first("project.name")
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["name"] != null
                          ? _vm._l(
                              _vm.customFieldMap["name"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "name" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.project[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "project[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isIdentifierVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("field.identifier"),
                                      "label-for": "identifier",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "identifier",
                                            type: "text",
                                            "data-vv-as":
                                              _vm.$t("field.identifier"),
                                            "data-vv-name":
                                              "project.identifier",
                                            maxlength: _vm.maxIdentifierLength,
                                            readonly: _vm.isIdentifierReadOnly,
                                            trim: "",
                                          },
                                          model: {
                                            value: _vm.project.identifier,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                "identifier",
                                                $$v
                                              )
                                            },
                                            expression: "project.identifier",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["identifier"] != null
                          ? _vm._l(
                              _vm.customFieldMap["identifier"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "identifier" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.project[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "project[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.customFieldMap["default"] != null
                          ? _vm._l(
                              _vm.customFieldMap["default"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.project[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "project[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isCompanyVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("label", { staticClass: "mr-1" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("project.field.company"))
                                      ),
                                    ]),
                                    !_vm.isCompanyReadOnly
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn-action",
                                            attrs: { id: "COMPANY_ADD" },
                                            on: {
                                              click: _vm.companySelectorToggle,
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: ["far", "plus"] },
                                            }),
                                            _c("b-popover", {
                                              attrs: {
                                                target: "COMPANY_ADD",
                                                placement: "top",
                                                triggers: "hover",
                                                content: _vm.$t(
                                                  "project.button.company_add"
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("BadgeGroup", {
                                      attrs: {
                                        readOnly: _vm.isCompanyReadOnly,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var item = ref.item
                                              var index = ref.index
                                              return [
                                                _c("Badge", {
                                                  key: index,
                                                  attrs: {
                                                    text: item.name,
                                                    variant: "primary",
                                                    pillable: !!item.pillable,
                                                    readOnly:
                                                      _vm.isCompanyReadOnly,
                                                  },
                                                  on: {
                                                    badgeRemove: function (
                                                      $event
                                                    ) {
                                                      return _vm.companyBadgeRemove(
                                                        index
                                                      )
                                                    },
                                                    badgeClick: function (
                                                      $event
                                                    ) {
                                                      return _vm.companyBadgeClick(
                                                        item.uuId
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3411449927
                                      ),
                                      model: {
                                        value: _vm.company,
                                        callback: function ($$v) {
                                          _vm.company = $$v
                                        },
                                        expression: "company",
                                      },
                                    }),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "mt-1 alert-danger form-field-alert",
                                        class: {
                                          "d-block": _vm.showCompanyError,
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first("staff.company")
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["companies"] != null
                          ? _vm._l(
                              _vm.customFieldMap["companies"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "companies" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.project[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "project[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isColorVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "color-container" },
                                  [
                                    _c("Color", {
                                      attrs: {
                                        disabled: _vm.isColorReadOnly,
                                        update: _vm.updatedColor,
                                      },
                                      model: {
                                        value: _vm.project.color,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.project, "color", $$v)
                                        },
                                        expression: "project.color",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.customFieldMap["color"] != null
                          ? _vm._l(
                              _vm.customFieldMap["color"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "color" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.project[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "project[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isLocationVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  { staticClass: "mb-0" },
                                  [
                                    _c("label", { staticClass: "mr-1" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("project.field.location"))
                                      ),
                                    ]),
                                    !_vm.isLocationReadOnly
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn-action",
                                            attrs: { id: "LOCATION_ADD" },
                                            on: { click: _vm.locSelectorOpen },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: ["far", "plus"] },
                                            }),
                                            _c("b-popover", {
                                              attrs: {
                                                target: "LOCATION_ADD",
                                                placement: "top",
                                                triggers: "hover",
                                                content: _vm.$t(
                                                  "project.button.location_add"
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("BadgeGroup", {
                                      staticClass: "mb-3",
                                      attrs: {
                                        readOnly: _vm.isLocationReadOnly,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var item = ref.item
                                              var index = ref.index
                                              return [
                                                _c("Badge", {
                                                  key: index,
                                                  attrs: {
                                                    text: item.name,
                                                    variant: "primary",
                                                    pillable: !!item.pillable,
                                                    readOnly:
                                                      _vm.isLocationReadOnly,
                                                  },
                                                  on: {
                                                    badgeRemove: function (
                                                      $event
                                                    ) {
                                                      return _vm.locationBadgeRemove(
                                                        index
                                                      )
                                                    },
                                                    badgeClick: function (
                                                      $event
                                                    ) {
                                                      return _vm.locationBadgeClick(
                                                        item.uuId
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1048688413
                                      ),
                                      model: {
                                        value: _vm.location,
                                        callback: function ($$v) {
                                          _vm.location = $$v
                                        },
                                        expression: "location",
                                      },
                                    }),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: {
                                          "d-block": _vm.showLocationError,
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first(
                                                "locSelector.name"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["locations"] != null
                          ? _vm._l(
                              _vm.customFieldMap["locations"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "locations" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.project[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "project[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isCustomerVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("label", { staticClass: "mr-1" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("project.field.customer"))
                                      ),
                                    ]),
                                    !_vm.isCustomerReadOnly
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn-action",
                                            attrs: { id: "CUSTOMER_ADD" },
                                            on: {
                                              click: _vm.customerSelectorToggle,
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: ["far", "plus"] },
                                            }),
                                            _c("b-popover", {
                                              attrs: {
                                                target: "CUSTOMER_ADD",
                                                placement: "top",
                                                triggers: "hover",
                                                content: _vm.$t(
                                                  "project.button.customer_add"
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("BadgeGroup", {
                                      attrs: {
                                        readOnly: _vm.isCustomerReadOnly,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var item = ref.item
                                              var index = ref.index
                                              return [
                                                _c("Badge", {
                                                  key: index,
                                                  attrs: {
                                                    text: item.name,
                                                    variant: "primary",
                                                    pillable: !!item.pillable,
                                                    readOnly:
                                                      _vm.isCustomerReadOnly,
                                                  },
                                                  on: {
                                                    badgeRemove: function (
                                                      $event
                                                    ) {
                                                      return _vm.customerBadgeRemove(
                                                        index
                                                      )
                                                    },
                                                    badgeClick: function (
                                                      $event
                                                    ) {
                                                      return _vm.customerBadgeClick(
                                                        item.uuId
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2515219908
                                      ),
                                      model: {
                                        value: _vm.customers,
                                        callback: function ($$v) {
                                          _vm.customers = $$v
                                        },
                                        expression: "customers",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["customers"] != null
                          ? _vm._l(
                              _vm.customFieldMap["customers"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "customers" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.project[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "project[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isPriorityVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("project.field.priority"),
                                      "label-for": "project-priority",
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.project.priority,
                                              expression: "project.priority",
                                            },
                                          ],
                                          staticClass: "custom-select",
                                          attrs: {
                                            id: "project-priority",
                                            disabled: _vm.isPriorityReadOnly,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.project,
                                                "priority",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._l(
                                            _vm.priorityOptions,
                                            function (opt, index) {
                                              return [
                                                _c(
                                                  "option",
                                                  {
                                                    key: index,
                                                    style: {
                                                      display:
                                                        opt.num < 0
                                                          ? "none"
                                                          : "block",
                                                    },
                                                    domProps: {
                                                      value: opt.value,
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(opt.text))]
                                                ),
                                              ]
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["priority"] != null
                          ? _vm._l(
                              _vm.customFieldMap["priority"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "priority" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.project[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "project[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isStageVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("label", { staticClass: "mr-1" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("project.field.status"))
                                      ),
                                    ]),
                                    !_vm.isStageReadOnly
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn-action",
                                            attrs: { id: "STATUS_ADD" },
                                            on: {
                                              click: _vm.statusSelectorToggle,
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: ["far", "plus"] },
                                            }),
                                            _c("b-popover", {
                                              attrs: {
                                                target: "STATUS_ADD",
                                                placement: "top",
                                                triggers: "hover",
                                                content: _vm.$t(
                                                  "project.button.status_add"
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("BadgeGroup", {
                                      attrs: { readOnly: _vm.isStageReadOnly },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var item = ref.item
                                              var index = ref.index
                                              return [
                                                _c("Badge", {
                                                  key: index,
                                                  attrs: {
                                                    text: item.name,
                                                    variant: "primary",
                                                    pillable: !!item.pillable,
                                                    readOnly:
                                                      _vm.isReadOnly ||
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        ["STAGE"]
                                                      ),
                                                  },
                                                  on: {
                                                    badgeRemove: function (
                                                      $event
                                                    ) {
                                                      return _vm.statusBadgeRemove(
                                                        index
                                                      )
                                                    },
                                                    badgeClick: function (
                                                      $event
                                                    ) {
                                                      return _vm.statusBadgeClick(
                                                        item.uuId
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        4225606322
                                      ),
                                      model: {
                                        value: _vm.statuses,
                                        callback: function ($$v) {
                                          _vm.statuses = $$v
                                        },
                                        expression: "statuses",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["status"] != null
                          ? _vm._l(
                              _vm.customFieldMap["status"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "status" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.project[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "project[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isScheduleStartVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "project.field.scheduleStart"
                                      ),
                                      "label-for": "startDate",
                                    },
                                  },
                                  [
                                    _c("b-form-datepicker", {
                                      staticClass: "mb-2",
                                      attrs: {
                                        id: "startDate",
                                        max: _vm.project.scheduleFinish,
                                        "value-as-date": "",
                                        "today-button": "",
                                        "reset-button": "",
                                        "close-button": "",
                                        "hide-header": "",
                                        "label-today-button":
                                          _vm.$t("date.today"),
                                        "label-reset-button":
                                          _vm.$t("date.reset"),
                                        "label-close-button":
                                          _vm.$t("date.close"),
                                        "today-button-variant": "primary",
                                        "reset-button-variant": "danger",
                                        "close-button-variant": "secondary",
                                        readonly: _vm.isScheduleStartReadOnly,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "button-content",
                                            fn: function (ref) {
                                              return [
                                                _c("font-awesome-icon", {
                                                  attrs: {
                                                    icon: [
                                                      "far",
                                                      "calendar-days",
                                                    ],
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2621928167
                                      ),
                                      model: {
                                        value: _vm.project.scheduleStart,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.project,
                                            "scheduleStart",
                                            $$v
                                          )
                                        },
                                        expression: "project.scheduleStart",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["scheduleStart"] != null
                          ? _vm._l(
                              _vm.customFieldMap["scheduleStart"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "scheduleStart" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.project[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "project[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isScheduleFinishVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "project.field.scheduleFinish"
                                      ),
                                      "label-for": "endDate",
                                    },
                                  },
                                  [
                                    _c("b-form-datepicker", {
                                      staticClass: "mb-2",
                                      attrs: {
                                        id: "endDate",
                                        min: _vm.project.scheduleStart,
                                        "value-as-date": "",
                                        "today-button": "",
                                        "reset-button": "",
                                        "close-button": "",
                                        "hide-header": "",
                                        "label-today-button":
                                          _vm.$t("date.today"),
                                        "label-reset-button":
                                          _vm.$t("date.reset"),
                                        "label-close-button":
                                          _vm.$t("date.close"),
                                        "today-button-variant": "primary",
                                        "reset-button-variant": "danger",
                                        "close-button-variant": "secondary",
                                        readonly: _vm.isScheduleFinishReadOnly,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "button-content",
                                            fn: function (ref) {
                                              return [
                                                _c("font-awesome-icon", {
                                                  attrs: {
                                                    icon: [
                                                      "far",
                                                      "calendar-days",
                                                    ],
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2621928167
                                      ),
                                      model: {
                                        value: _vm.project.scheduleFinish,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.project,
                                            "scheduleFinish",
                                            $$v
                                          )
                                        },
                                        expression: "project.scheduleFinish",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["scheduleFinish"] != null
                          ? _vm._l(
                              _vm.customFieldMap["scheduleFinish"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "scheduleFinish" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.project[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "project[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isFixedDurationVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "project.field.fixedDuration"
                                      ),
                                      "label-for": "project-fixed-duration",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        !_vm.isFixedDurationReadOnly
                                          ? _c(
                                              "b-input-group-prepend",
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      id: "FIXED_DURATION_SUBTRACT",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.fixedDurationAddMinus(
                                                          -1
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: ["far", "minus"],
                                                      },
                                                    }),
                                                    _c("b-popover", {
                                                      attrs: {
                                                        target:
                                                          "FIXED_DURATION_SUBTRACT",
                                                        placement: "top",
                                                        triggers: "hover",
                                                        content: _vm.$t(
                                                          "task.button.duration_subtract"
                                                        ),
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c("b-form-input", {
                                          class: _vm.fixedDurationClass,
                                          attrs: {
                                            id: "project-fixed-duration",
                                            type: "text",
                                            "data-vv-as": _vm.$t(
                                              "project.field.fixedDuration"
                                            ),
                                            "data-vv-name":
                                              "project.fixedDuration",
                                            "data-vv-delay": "500",
                                            readonly:
                                              _vm.isFixedDurationReadOnly,
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              return _vm.onFixedDurationKeyDown(
                                                $event
                                              )
                                            },
                                            keyup: function ($event) {
                                              return _vm.onFixedDurationKeyUp(
                                                $event
                                              )
                                            },
                                            change: _vm.onFixedDurationChange,
                                          },
                                          model: {
                                            value: _vm.project.fixedDuration,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                "fixedDuration",
                                                $$v
                                              )
                                            },
                                            expression: "project.fixedDuration",
                                          },
                                        }),
                                        !_vm.isFixedDurationReadOnly
                                          ? _c(
                                              "b-input-group-append",
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      id: "FIXED_DURATION_ADD",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.fixedDurationAddMinus(
                                                          1
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: ["far", "plus"],
                                                      },
                                                    }),
                                                    _c("b-popover", {
                                                      attrs: {
                                                        target:
                                                          "FIXED_DURATION_ADD",
                                                        placement: "top",
                                                        triggers: "hover",
                                                        content: _vm.$t(
                                                          "task.button.duration_add"
                                                        ),
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.fixedDurationAlert
                                          ? _c(
                                              "b-input-group-append",
                                              [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass:
                                                      "fixed-duration-alert",
                                                    attrs: {
                                                      id: "fixed-duration-alert",
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: [
                                                          "far",
                                                          "circle-exclamation",
                                                        ],
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        "fixed-duration-alert",
                                                      triggers: "hover",
                                                      placement: "topleft",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.fixedDurationAlertMsg
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: {
                                          "d-block": _vm.showFixedDurationError,
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first("fixedDuration")
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["fixedDuration"] != null
                          ? _vm._l(
                              _vm.customFieldMap["fixedDuration"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "fixedDuration" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.project[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "project[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isDurationVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "project.field.estimatedDuration"
                                      ),
                                      "label-for": "project-duration",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "project-duration",
                                            type: "text",
                                            readonly: "",
                                          },
                                          model: {
                                            value:
                                              _vm.project.estimatedDuration,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                "estimatedDuration",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "project.estimatedDuration",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["estimatedDuration"] != null
                          ? _vm._l(
                              _vm.customFieldMap["estimatedDuration"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "estimatedDuration" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.project[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "project[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isActualDurationVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("label", { staticClass: "mr-1" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("project.field.actualDuration")
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "project-actual-duration",
                                            type: "text",
                                            readonly: "",
                                          },
                                          model: {
                                            value: _vm.project.actualDuration,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                "actualDuration",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "project.actualDuration",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["actualDuration"] != null
                          ? _vm._l(
                              _vm.customFieldMap["actualDuration"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "actualDuration" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.project[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "project[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isAutoSchedulingVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "project.field.autoScheduling"
                                      ),
                                      "label-for": "project-autoschedule",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-select",
                                      {
                                        attrs: {
                                          id: "project-autoschedule",
                                          disabled:
                                            _vm.isAutoSchedulingReadOnly,
                                        },
                                        model: {
                                          value: _vm.project.autoScheduling,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.project,
                                              "autoScheduling",
                                              $$v
                                            )
                                          },
                                          expression: "project.autoScheduling",
                                        },
                                      },
                                      [
                                        [
                                          _c(
                                            "option",
                                            { domProps: { value: true } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "project.autoschedule.auto"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "option",
                                            { domProps: { value: false } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "project.autoschedule.manual"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["autoScheduling"] != null
                          ? _vm._l(
                              _vm.customFieldMap["autoScheduling"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "autoScheduling" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.project[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "project[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isScheduleModeVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "project.field.scheduleMode"
                                      ),
                                      "label-for": "scheduleMode",
                                    },
                                  },
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        id: "scheduleMode",
                                        options: _vm.optionscheduleMode,
                                        disabled: _vm.isScheduleModeReadOnly,
                                      },
                                      model: {
                                        value: _vm.project.scheduleMode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.project,
                                            "scheduleMode",
                                            $$v
                                          )
                                        },
                                        expression: "project.scheduleMode",
                                      },
                                    }),
                                    _c("b-form-text", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.project.scheduleMode == "ASAP"
                                            ? _vm.$t(
                                                "project.select_schedule_hint_asap"
                                              )
                                            : _vm.project.scheduleMode == "ALAP"
                                            ? _vm.$t(
                                                "project.select_schedule_hint_alap"
                                              )
                                            : ""
                                        ) + " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["scheduleMode"] != null
                          ? _vm._l(
                              _vm.customFieldMap["scheduleMode"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "scheduleMode" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.project[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "project[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isDescriptionVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "project.field.description"
                                      ),
                                      "label-for": "description",
                                    },
                                  },
                                  [
                                    _c("b-form-textarea", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: {
                                            max: _vm.maxDescriptionLength,
                                          },
                                          expression:
                                            "{ max: maxDescriptionLength }",
                                        },
                                      ],
                                      attrs: {
                                        id: "description",
                                        placeholder: _vm.isDescriptionReadOnly
                                          ? ""
                                          : _vm.$t(
                                              "project.placeholder.description"
                                            ),
                                        "data-vv-as": _vm.$t(
                                          "project.field.description"
                                        ),
                                        "data-vv-name": "project.description",
                                        "data-vv-delay": "500",
                                        "max-rows": 6,
                                        readonly: _vm.isDescriptionReadOnly,
                                        trim: "",
                                        rows: 3,
                                      },
                                      model: {
                                        value: _vm.project.description,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.project,
                                            "description",
                                            $$v
                                          )
                                        },
                                        expression: "project.description",
                                      },
                                    }),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: {
                                          "d-block": _vm.showDescriptionError,
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first(
                                                "project.description"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["description"] != null
                          ? _vm._l(
                              _vm.customFieldMap["description"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "description" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.project[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "project[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isTagVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("TagList", {
                                      attrs: {
                                        holderId: _vm.id,
                                        tags: _vm.tags,
                                        readOnly: _vm.isTagReadOnly,
                                      },
                                      on: { modified: _vm.tagsModified },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["tags"] != null
                          ? _vm._l(
                              _vm.customFieldMap["tags"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "tags" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.project[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "project[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isFixedCostVisible || _vm.isFixedCostNetVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c(
                                  "b-form-select",
                                  {
                                    staticClass: "title-dropdown mb-1",
                                    model: {
                                      value: _vm.fixedCostMode,
                                      callback: function ($$v) {
                                        _vm.fixedCostMode = $$v
                                      },
                                      expression: "fixedCostMode",
                                    },
                                  },
                                  [
                                    _vm.isFixedCostVisible
                                      ? _c(
                                          "option",
                                          { attrs: { value: "gross" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "project.field.fixedCost"
                                                )
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.isFixedCostNetVisible
                                      ? _c(
                                          "option",
                                          { attrs: { value: "net" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "project.field.fixedCostNet"
                                                )
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _vm.fixedCostMode === "gross"
                                          ? _c("b-form-input", {
                                              attrs: {
                                                id: "cost",
                                                type: "number",
                                                min: 0.0,
                                                step: 1,
                                                "lazy-formatter": "",
                                                formatter: _vm.floatFormatter(
                                                  null,
                                                  0.0
                                                ),
                                                readonly:
                                                  _vm.isFixedCostReadOnly,
                                              },
                                              model: {
                                                value: _vm.project.fixedCost,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.project,
                                                    "fixedCost",
                                                    $$v
                                                  )
                                                },
                                                expression: "project.fixedCost",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.fixedCostMode === "net"
                                          ? _c("b-form-input", {
                                              attrs: {
                                                id: "costNet",
                                                type: "number",
                                                min: 0.0,
                                                step: 1,
                                                "lazy-formatter": "",
                                                formatter: _vm.floatFormatter(
                                                  null,
                                                  0.0
                                                ),
                                                readonly: "",
                                              },
                                              model: {
                                                value: _vm.project.fixedCostNet,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.project,
                                                    "fixedCostNet",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "project.fixedCostNet",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["fixedCost"] != null
                          ? _vm._l(
                              _vm.customFieldMap["fixedCost"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "fixedCost" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.project[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "project[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isCurrencyCodeVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("task.field.currencyCode"),
                                    },
                                  },
                                  [
                                    _c("b-input-group", [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.project.currencyCode,
                                              expression:
                                                "project.currencyCode",
                                            },
                                          ],
                                          staticClass: "custom-select",
                                          attrs: {
                                            id: "project-priority",
                                            disabled:
                                              _vm.isCurrencyCodeReadOnly,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.project,
                                                "currencyCode",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._l(
                                            _vm.currencyOpts,
                                            function (opt, index) {
                                              return [
                                                _c(
                                                  "option",
                                                  {
                                                    key: index,
                                                    style: {
                                                      display:
                                                        opt.num < 0
                                                          ? "none"
                                                          : "block",
                                                    },
                                                    domProps: {
                                                      value: opt.value,
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(opt.text))]
                                                ),
                                              ]
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ]),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: {
                                          "d-block": _vm.showCurrencyError,
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first(
                                                "project.currencyCode"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["currencyCode"] != null
                          ? _vm._l(
                              _vm.customFieldMap["currencyCode"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "currencyCode" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.project[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "project[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isEstimatedCostVisible ||
                        _vm.isEstimatedCostNetVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c(
                                  "b-form-select",
                                  {
                                    staticClass: "title-dropdown mb-1",
                                    model: {
                                      value: _vm.estimatedCostMode,
                                      callback: function ($$v) {
                                        _vm.estimatedCostMode = $$v
                                      },
                                      expression: "estimatedCostMode",
                                    },
                                  },
                                  [
                                    _vm.isEstimatedCostVisible
                                      ? _c(
                                          "option",
                                          { attrs: { value: "gross" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "project.field.estimatedCost"
                                                )
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.isEstimatedCostNetVisible
                                      ? _c(
                                          "option",
                                          { attrs: { value: "net" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "project.field.estimatedCostNet"
                                                )
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _vm.estimatedCostMode === "gross"
                                          ? _c("b-form-input", {
                                              attrs: {
                                                id: "estimatedCost",
                                                value: _vm.estimatedCost,
                                                readonly: "",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.estimatedCostMode === "net"
                                          ? _c("b-form-input", {
                                              attrs: {
                                                id: "estimatedCostNet",
                                                value: _vm.estimatedCostNet,
                                                readonly: "",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["estimatedCost"] != null
                          ? _vm._l(
                              _vm.customFieldMap["estimatedCost"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "estimatedCost" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.project[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "project[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isActualCostVisible || _vm.isActualCostNetVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c(
                                  "b-form-select",
                                  {
                                    staticClass: "title-dropdown mb-1",
                                    model: {
                                      value: _vm.actualCostMode,
                                      callback: function ($$v) {
                                        _vm.actualCostMode = $$v
                                      },
                                      expression: "actualCostMode",
                                    },
                                  },
                                  [
                                    _vm.isActualCostVisible
                                      ? _c(
                                          "option",
                                          { attrs: { value: "gross" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "project.field.actualCost"
                                                )
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.isActualCostNetVisible
                                      ? _c(
                                          "option",
                                          { attrs: { value: "net" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "project.field.actualCostNet"
                                                )
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _vm.actualCostMode === "gross"
                                          ? _c("b-form-input", {
                                              class: {
                                                colorRed: _vm.actualCostClass,
                                                "forced-border-radius":
                                                  _vm.actualCostAlert,
                                              },
                                              attrs: {
                                                id: "actualCost",
                                                value: _vm.actualCost,
                                                readonly: "",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.actualCostMode === "net"
                                          ? _c("b-form-input", {
                                              class: {
                                                colorRed: _vm.actualCostClass,
                                                "forced-border-radius":
                                                  _vm.actualCostAlert,
                                              },
                                              attrs: {
                                                id: "actualCostNet",
                                                value: _vm.actualCostNet,
                                                readonly: "",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.actualCostAlert
                                          ? _c(
                                              "b-input-group-append",
                                              [
                                                _c(
                                                  "strong",
                                                  {
                                                    staticClass:
                                                      "ml-1 actual-cost-alert",
                                                    attrs: {
                                                      id: "actualCostAlert",
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: [
                                                          "far",
                                                          "circle-exclamation",
                                                        ],
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target: "actualCostAlert",
                                                      triggers: "hover",
                                                      placement: "topleft",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.actualCostAlertMsg
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["actualCost"] != null
                          ? _vm._l(
                              _vm.customFieldMap["actualCost"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "actualCost" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.project[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "project[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isRebateVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("label", { staticClass: "mr-1" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("task.field.rebates"))
                                      ),
                                    ]),
                                    !_vm.isRebateReadOnly
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn-action",
                                            attrs: { id: "REBATE_ADD" },
                                            on: {
                                              click: _vm.rebateSelectorToggle,
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: ["far", "plus"] },
                                            }),
                                            _c("b-popover", {
                                              attrs: {
                                                target: "REBATE_ADD",
                                                placement: "top",
                                                triggers: "hover",
                                                content: _vm.$t(
                                                  "project.button.rebate_add"
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-form-text",
                                      { staticClass: "rebate-total mr-1" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "task.field.total_rebate",
                                                [_vm.totalRebate]
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("BadgeGroup", {
                                      attrs: { readOnly: _vm.isRebateReadOnly },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var item = ref.item
                                              var index = ref.index
                                              return [
                                                _c("Badge", {
                                                  key: index,
                                                  attrs: {
                                                    text:
                                                      item.name +
                                                      " (" +
                                                      _vm.formatRebate(
                                                        item.rebate
                                                      ) +
                                                      "%)",
                                                    variant: "primary",
                                                    pillable: !!item.pillable,
                                                    readOnly:
                                                      _vm.isRebateReadOnly,
                                                  },
                                                  on: {
                                                    badgeRemove: function (
                                                      $event
                                                    ) {
                                                      return _vm.rebateBadgeRemove(
                                                        index
                                                      )
                                                    },
                                                    badgeClick: function (
                                                      $event
                                                    ) {
                                                      return _vm.rebateBadgeClick(
                                                        item.uuId
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        486676024
                                      ),
                                      model: {
                                        value: _vm.rebates,
                                        callback: function ($$v) {
                                          _vm.rebates = $$v
                                        },
                                        expression: "rebates",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["rebates"] != null
                          ? _vm._l(
                              _vm.customFieldMap["rebates"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "rebates" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.project[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "project[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isStageVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("StageList", {
                                      attrs: {
                                        holderId: _vm.id,
                                        stages: _vm.stages,
                                        readOnly: _vm.isStageReadOnly,
                                        filterEntity: "TASK",
                                      },
                                      on: { modified: _vm.stagesModified },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["taskStages"] != null
                          ? _vm._l(
                              _vm.customFieldMap["taskStages"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "taskStages" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.project[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "project[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isStorageFileVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("label", { staticClass: "mr-1" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("project.field.files"))
                                      ),
                                    ]),
                                    !_vm.isStorageFileReadOnly
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn-action",
                                            attrs: { id: "FILE_ADD" },
                                            on: {
                                              click: _vm.fileSelectorToggle,
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: ["far", "plus"] },
                                            }),
                                            _c("b-popover", {
                                              attrs: {
                                                target: "FILE_ADD",
                                                placement: "top",
                                                triggers: "hover",
                                                content: _vm.$t(
                                                  "project.button.file_add"
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("BadgeGroup", {
                                      attrs: {
                                        readOnly: _vm.isStorageFileReadOnly,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var item = ref.item
                                              var index = ref.index
                                              return [
                                                _c("Badge", {
                                                  key: index,
                                                  attrs: {
                                                    text: _vm.labelFilename(
                                                      item.name,
                                                      item.type
                                                    ),
                                                    variant: "primary",
                                                    pillable: !!item.pillable,
                                                    readOnly:
                                                      _vm.isStorageFileReadOnly,
                                                    enableClickWhenReadOnly: "",
                                                  },
                                                  on: {
                                                    badgeRemove: function (
                                                      $event
                                                    ) {
                                                      return _vm.fileBadgeRemove(
                                                        index
                                                      )
                                                    },
                                                    badgeClick: function (
                                                      $event
                                                    ) {
                                                      return _vm.fileBadgeClick(
                                                        item
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1579555038
                                      ),
                                      model: {
                                        value: _vm.files,
                                        callback: function ($$v) {
                                          _vm.files = $$v
                                        },
                                        expression: "files",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["files"] != null
                          ? _vm._l(
                              _vm.customFieldMap["files"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "files" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.project[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "project[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isNoteVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("NoteList", {
                                      attrs: {
                                        readOnly: _vm.isNoteReadOnly,
                                        notes: _vm.notes,
                                      },
                                      on: {
                                        add: _vm.addNote,
                                        edit: _vm.editNote,
                                        toRemove: _vm.removeNote,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["notes"] != null
                          ? _vm._l(
                              _vm.customFieldMap["notes"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "notes" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.project[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.project,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "project[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("project.confirmation.title_unsaved_change"),
            "ok-title": _vm.$t("button.continue"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmUnsavedChangeOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.confirmUnsavedChangeOk },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.continue")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.confirmUnsavedChangeShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "confirmUnsavedChangeShow", $$v)
            },
            expression: "state.confirmUnsavedChangeShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _c("p", [
              _vm._v(_vm._s(_vm.$t("project.confirmation.unsaved_change"))),
            ]),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("project.confirmation.title_apply_rebates"),
            "ok-title": _vm.$t("button.yes"),
            "cancel-title": _vm.$t("button.no"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.applyRebates },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.applyRebates },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.yes")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.no")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.promptApplyRebates,
            callback: function ($$v) {
              _vm.promptApplyRebates = $$v
            },
            expression: "promptApplyRebates",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _c("p", [
              _vm._v(_vm._s(_vm.$t("project.confirmation.apply_rebates"))),
            ]),
          ]),
        ]
      ),
      _vm.state.customerSelectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.state.customerSelectorShow,
              entityService: _vm.customerUtil,
              preselected: _vm.customerEditId,
              entity: "CUSTOMER",
              nonAdmin: "",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "customerSelectorShow", $event)
              },
              ok: _vm.customerSelectorOk,
            },
          })
        : _vm._e(),
      _vm.state.statusSelectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.state.statusSelectorShow,
              entityService: _vm.stageUtil,
              preselected: _vm.statusEditId,
              entity: "STAGE",
              nonAdmin: "",
              tagFilter: { entity: "PROJECT", tags: _vm.tagNames },
              singleSelection: "",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "statusSelectorShow", $event)
              },
              ok: _vm.statusSelectorOk,
            },
          })
        : _vm._e(),
      _vm.state.statusEditShow
        ? _c("StageModal", {
            attrs: {
              id: _vm.statusEditId,
              show: _vm.state.statusEditShow,
              title: _vm.statusTitle,
              readOnly: _vm.isReadOnly,
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "statusEditShow", $event)
              },
            },
          })
        : _vm._e(),
      _vm.state.companySelectorShow
        ? _c("CompanySelectorModalForAdmin", {
            attrs: {
              show: _vm.state.companySelectorShow,
              preselected: _vm.companyEditId,
              nonAdmin: "",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "companySelectorShow", $event)
              },
              ok: _vm.companySelectorOk,
            },
          })
        : _vm._e(),
      _vm.state.fileSelectorShow
        ? _c("FileSelectorModal", {
            attrs: { show: _vm.state.fileSelectorShow },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "fileSelectorShow", $event)
              },
              ok: _vm.fileSelectorOk,
            },
          })
        : _vm._e(),
      _vm.state.rebateSelectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.state.rebateSelectorShow,
              entityService: _vm.rebateUtil,
              entity: "REBATE",
              nonAdmin: "",
              preselected: _vm.rebateEdit.uuId,
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "rebateSelectorShow", $event)
              },
              ok: _vm.rebateSelectorOk,
            },
          })
        : _vm._e(),
      _vm.state.locSelectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.state.locSelectorShow,
              entityService: _vm.locationUtil,
              entity: "LOCATION",
              nonAdmin: "",
              singleSelection: "",
              preselected: _vm.locationEditId,
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "locSelectorShow", $event)
              },
              ok: _vm.locSelectorOk,
            },
          })
        : _vm._e(),
      _vm.downloadProgressShow
        ? _c("DownloadProgressModal", {
            attrs: {
              show: _vm.downloadProgressShow,
              downloadPercentage: _vm.downloadPercentage,
            },
            on: {
              "update:show": function ($event) {
                _vm.downloadProgressShow = $event
              },
              cancel: _vm.downloadCancel,
            },
          })
        : _vm._e(),
      _vm.exists
        ? [
            _vm.state.historyShow
              ? _c("GenericHistoryModal", {
                  attrs: {
                    show: _vm.state.historyShow,
                    id: _vm.id,
                    entityType: "PROJECT",
                    customFields: _vm.customFields,
                    links:
                      "COMPANY,CUSTOMER,LOCATION,NOTE,REBATE,STAGE,STORAGE_FILE,TAG",
                  },
                  on: {
                    "update:show": function ($event) {
                      return _vm.$set(_vm.state, "historyShow", $event)
                    },
                  },
                })
              : _vm._e(),
            _vm.state.noteShow
              ? _c("NoteModal", {
                  attrs: { show: _vm.state.noteShow, note: _vm.note },
                  on: {
                    "update:show": function ($event) {
                      return _vm.$set(_vm.state, "noteShow", $event)
                    },
                    toAdd: _vm.toAddNote,
                    toUpdate: _vm.toUpdateNote,
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }